<template>
  <div>
    <v-dialog v-model="recalcularDialog" scrollable persistent max-width="500">
      <v-card>
        <v-card-title style="display: block" class="pb-1">
          <v-icon x-large class="mr-3">mdi-database-refresh-outline</v-icon>
          {{ title }}
          <v-btn
            @click="recalcularDialog = false"
            style="position: absolute; right: 5px; top: 5px"
            icon
          >
            <v-icon>mdi-close-circle-outline</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <p>Selecione o período para recálculo:</p>
          <v-menu
            v-model="dateMenu"
            :close-on-click="competenciaRecalculo.length == 2"
            :close-on-content-click="false"
            transition="scroll-y-transition"
            offset-y
            right
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="competenciaRecalculo | toMonthPeriod"
                dense
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                @click:prepend.stop="on.click"
                v-on="on"
                hide-details
                class="mb-n1 mt-0 mr-3"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="competenciaRecalculo"
              range
              type="month"
              @click:month="dateMenu = competenciaRecalculo.length != 2"
              no-title
            ></v-date-picker>
          </v-menu>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="px-6 pb-3 pt-0">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="pr-5"
            dark
            depressed
            @click="recalcularDialog = false"
          >
            <v-icon left>mdi-chevron-left</v-icon>Cancelar
          </v-btn>
          <v-btn
            color="primary"
            dark
            depressed
            class="px-5 ml-3"
            @click="execute(); recalcularDialog = false"
          >
            Executar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div v-bind="$attrs" @click="recalcularDialog = true">
      <v-icon>mdi-database-refresh-outline</v-icon>
      {{ label }}
    </div>
  </div>
</template>

<script>
export default {
  name: "recalcule-btn",
  created: function () {
    this.competenciaRecalculo = this.getFilters('monthly');
  },
  data: function () {
    return ({
      competenciaRecalculo: [],
      dateMenu: false,
      recalcularDialog: false,
    });
  },
  inheritAttrs: false,
  methods: {
    execute: function () {
      if (this.competenciaRecalculo.length < 2) {
        return;
      }

      if (this.resource) {
        this.resource.get();
      }

      this.$emit('change', this.competenciaRecalculo);
    },
  },
  props: {
    label: {
      type: String,
      default: "Recalcular",
    },
    title: {
      type: String,
      default: "Recalcular relatório",
    },
    resource: {
      type: Object,
    },
  },
};
</script>
